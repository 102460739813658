@font-face {
  font-family: 'Noto Serif';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Noto Serif'), local('NotoSerif'), url(https://fonts.gstatic.com/s/notoserif/v8/ga6Iaw1J5X9T9RW6j9bNfFkWbQ.ttf) format('truetype');
}
@font-face {
  font-family: 'Noto Serif';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local('Noto Serif Bold'), local('NotoSerif-Bold'), url(https://fonts.gstatic.com/s/notoserif/v8/ga6Law1J5X9T9RW6j9bNdOwzfRmedA.ttf) format('truetype');
}
body {
  min-height: 100vh;
  height: 100%;
  font-family: 'Noto Serif', serif;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
body.no-scroll {
  overflow: hidden;
  max-height: 100vh;
}
body ul,
body ol,
body p,
body h1,
body h2,
body h3,
body h4,
body h5,
body h6 {
  margin: 0;
  padding: 0;
}
body a {
  text-decoration: none !important;
}
body input,
body select,
body textarea {
  outline-color: transparent;
}
body input:focus,
body select:focus,
body textarea:focus {
  outline-color: #006d3a;
  border: 1px solid transparent;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 109, 58, 0.3);
}
.alert-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  overflow-y: scroll;
  background: rgba(0, 0, 0, 0.2);
  z-index: 555;
  opacity: 0;
  visibility: hidden;
  transition: all 0.4s;
}
.alert-wrapper .alert-service {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  width: 100%;
  transform: translateX(8px);
  padding: 50px 0;
}
.alert-wrapper .alert-service .service-inner {
  padding: 50px;
  background: #fff;
  width: 100%;
  box-shadow: 0px 0px 32px 1px rgba(0, 0, 0, 0.15);
  text-align: center;
  border-top: 5px solid #fff;
  position: relative;
  transform: scale(0.8);
  transition: all 0.5s;
}
.alert-wrapper .alert-service .service-inner .inner-dismiss {
  top: 10px;
  right: 10px;
  position: absolute;
  border: 0;
  background: none;
  padding: 0;
  cursor: pointer;
  display: flex;
  padding: 5px;
  outline: none;
}
.alert-wrapper .alert-service .service-inner .inner-dismiss img {
  height: 15px;
}
.alert-wrapper .alert-service .service-inner.success {
  border-top: 5px solid #78b042;
}
.alert-wrapper .alert-service .service-inner.warning {
  border-top: 5px solid #d51a27;
}
.alert-wrapper .alert-service .service-inner.info {
  border-top: 5px solid #1a88d5;
}
.alert-wrapper.active {
  opacity: 1;
  visibility: visible;
}
.alert-wrapper.active .alert-service .service-inner {
  transform: none;
}
.modal-service-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  overflow-y: scroll;
  background: rgba(0, 0, 0, 0.5);
  z-index: 555;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s;
}
.modal-service-wrapper.active {
  opacity: 1;
  visibility: visible;
}
.modal-service-wrapper .modal-service {
  padding: 50px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100%;
}
.modal-service-wrapper .modal-service .service-inner {
  background: #fff;
  width: 700px;
  max-width: 100%;
  padding: 50px 25px 25px 25px;
  text-align: center;
  position: relative;
}
.modal-service-wrapper .modal-service .service-inner .service-button {
  position: absolute;
  top: 20px;
  right: 20px;
  border: none;
  background: none;
  padding: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.modal-service-wrapper .modal-service .service-inner .service-button .button-bar {
  display: block;
  position: absolute;
  width: 20px;
  height: 2px;
  background: #222;
}
.modal-service-wrapper .modal-service .service-inner .service-button .button-bar:nth-child(1) {
  transform: rotate(45deg);
}
.modal-service-wrapper .modal-service .service-inner .service-button .button-bar:nth-child(2) {
  transform: rotate(-45deg);
}
.modal-service-wrapper .modal-service .service-inner .service-content .heading {
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 20px;
}
.modal-service-wrapper .modal-service .service-inner .service-content .btn {
  display: flex;
  justify-content: center;
  margin-top: 5px;
}
.modal-service-wrapper .modal-service .service-inner .service-buttons {
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.modal-service-wrapper .modal-service .service-inner .service-buttons .btn-link {
  padding: 0;
  text-transform: uppercase;
  font-weight: 700;
  text-decoration: none;
}
.btn {
  border: none;
  background: none;
  cursor: pointer;
  padding: 8px 17px;
}
.btn.btn-default {
  background-color: #006d3a;
  transition: background-color 250ms ease;
  color: #fff;
  font-size: 14px;
}
.btn.btn-default:hover {
  background-color: #fff;
  color: #006d3a;
}
.btn.btn-border {
  border: 1px solid rgba(255, 255, 255, 0.7);
  text-transform: lowercase;
  transition: border 250ms ease;
  color: #fff;
}
.btn.btn-border:hover {
  border: 1px solid #fff;
}
.btn.btn-default-border {
  border: 1px solid transparent;
}
.btn.btn-default-border:hover {
  border: 1px solid #006d3a;
}
.btn.btn-default-light {
  color: #fff;
  background-color: rgba(92, 184, 92, 0.8);
}
.btn.btn-default-light:hover {
  background-color: #5cb85c;
}
.header-background {
  background: url(../img/pictures/fotolia_79200372.jpg);
  background-attachment: fixed;
  background-size: cover;
  background-position: bottom;
  padding-top: 160px;
  padding-bottom: 2em;
  margin-top: -150px;
  height: 260px;
  z-index: 10;
}
.header-background h1 {
  color: #fff;
  margin-top: 20px;
  text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.5);
}
.heading {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
.heading h1,
.heading h2 {
  color: #333;
  font-size: 42px;
  line-height: 55px;
  margin-bottom: 20px;
}
.heading p {
  width: 80%;
  margin-top: 20px;
  text-align: center;
  font-size: 20px;
}
.heading-subpage {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 30px;
  text-align: center;
}
.heading-subpage h1,
.heading-subpage h2 {
  color: #333;
  font-size: 28px;
  line-height: 45px;
  margin-bottom: 10px;
}
.heading-subpage p {
  width: 90%;
  margin-top: 10px;
  text-align: center;
  font-size: 20px;
  line-height: 26px;
}
nav.section-header {
  background: url(../img/backgrounds/dark-menu.png) repeat;
  position: relative;
  opacity: 0.9;
  z-index: 22;
}
nav.section-header .header-flexbox {
  padding: 18px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
nav.section-header .header-flexbox .logo-wrapper {
  width: 25%;
}
nav.section-header .header-flexbox .logo-wrapper .flexbox-logo img {
  width: 260px;
}
nav.section-header .header-flexbox .wrapper {
  display: flex;
  flex-direction: column;
}
nav.section-header .header-flexbox .wrapper .header-top {
  padding-bottom: 30px;
  display: flex;
  justify-content: flex-end;
}
nav.section-header .header-flexbox .wrapper .header-top .top-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
nav.section-header .header-flexbox .wrapper .header-top .top-wrapper form {
  display: flex;
  align-items: center;
  margin-right: 15px;
}
nav.section-header .header-flexbox .wrapper .header-top .top-wrapper form input {
  padding: 6px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 220px;
  background: transparent;
  transition: all 200ms ease;
  color: #fff;
}
nav.section-header .header-flexbox .wrapper .header-top .top-wrapper form ::placeholder {
  color: #ccc;
  font-size: 14px;
}
nav.section-header .header-flexbox .wrapper .header-top .top-wrapper form .search {
  border: 1px solid #fff;
  border-radius: 5px;
  padding: 4px 12px;
  outline: none;
  background: none;
  margin-left: 3px;
  cursor: pointer;
}
nav.section-header .header-flexbox .wrapper .header-top .top-wrapper form .search:hover {
  background: #006d3a;
  border: none;
  border: 1px solid transparent;
}
nav.section-header .header-flexbox .wrapper .header-top .top-wrapper form .search img {
  width: 17px;
}
nav.section-header .header-flexbox .wrapper .header-top .top-wrapper .social-media {
  border: 1px solid #fff;
  border-radius: 5px;
  padding: 4px 13px;
  margin-right: 3px;
}
nav.section-header .header-flexbox .wrapper .header-top .top-wrapper .social-media:hover {
  background: #006d3a;
  border: 1px solid transparent;
}
nav.section-header .header-flexbox .wrapper .header-top .top-wrapper .social-media img {
  width: 15px;
}
nav.section-header .header-flexbox .wrapper .flexbox-nav-wrapper .nav-button-close {
  position: absolute;
  display: none;
  width: 100%;
  top: 20px;
  left: 0;
}
nav.section-header .header-flexbox .wrapper .flexbox-nav-wrapper .nav-button-close .container {
  display: flex;
  justify-content: flex-end;
}
nav.section-header .header-flexbox .wrapper .flexbox-nav-wrapper .nav-button-close .container:before,
nav.section-header .header-flexbox .wrapper .flexbox-nav-wrapper .nav-button-close .container:after {
  content: none;
}
nav.section-header .header-flexbox .wrapper .flexbox-nav-wrapper .nav-button-close button {
  outline: none;
  border: none;
  background: none;
  padding: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}
nav.section-header .header-flexbox .wrapper .flexbox-nav-wrapper .nav-button-close button .button-bar {
  display: block;
  position: absolute;
  width: 30px;
  height: 2px;
  background: #222;
}
nav.section-header .header-flexbox .wrapper .flexbox-nav-wrapper .nav-button-close button .button-bar:nth-child(1) {
  transform: rotate(45deg);
}
nav.section-header .header-flexbox .wrapper .flexbox-nav-wrapper .nav-button-close button .button-bar:nth-child(2) {
  transform: rotate(-45deg);
}
nav.section-header .header-flexbox .wrapper .flexbox-nav-wrapper .header-bottom .flexbox-nav {
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #fff;
  padding-top: 10px;
}
nav.section-header .header-flexbox .wrapper .flexbox-nav-wrapper .header-bottom .flexbox-nav li {
  padding: 0 10px;
}
nav.section-header .header-flexbox .wrapper .flexbox-nav-wrapper .header-bottom .flexbox-nav li a {
  position: relative;
  color: #fff;
  font-size: 14px;
  transition: all 250ms ease;
}
nav.section-header .header-flexbox .wrapper .flexbox-nav-wrapper .header-bottom .flexbox-nav li a:hover {
  color: #006d3a;
}
nav.section-header .header-flexbox .wrapper .flexbox-nav-wrapper .header-bottom .flexbox-nav li a:hover::before {
  content: '';
  position: absolute;
  top: -15px;
  width: 100%;
  height: 3px;
  background: #006d3a;
}
nav.section-header .header-flexbox .nav-button {
  padding: 20px;
  border: none;
  background: none;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  outline: none;
  display: none;
}
nav.section-header .header-flexbox .nav-button .button-bar {
  height: 2px;
  width: 24px;
  background: #006d3a;
  border-radius: 10px;
  transition: all 0.4s;
}
nav.section-header .header-flexbox .nav-button .button-bar:nth-child(2) {
  margin-top: 4px;
  width: 27px;
}
nav.section-header .header-flexbox .nav-button .button-bar:nth-child(3) {
  margin-top: 4px;
  width: 30px;
}
nav.section-header .header-flexbox .nav-button:hover .button-bar,
nav.section-header .header-flexbox .nav-button:focus .button-bar {
  width: 30px !important;
}
section.section-welcome {
  margin-top: -140px;
  height: 550px;
}
section.section-welcome .welcome-wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
}
section.section-welcome .welcome-wrapper .owl-carousel-welcome {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
section.section-welcome .welcome-wrapper .owl-carousel-welcome .owl-stage,
section.section-welcome .welcome-wrapper .owl-carousel-welcome .owl-stage-outer,
section.section-welcome .welcome-wrapper .owl-carousel-welcome .owl-item,
section.section-welcome .welcome-wrapper .owl-carousel-welcome .item {
  height: 100%;
}
section.section-welcome .welcome-wrapper .owl-carousel-welcome .item {
  position: relative;
}
section.section-welcome .welcome-wrapper .owl-carousel-welcome .item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
section.section-welcome .welcome-wrapper .owl-carousel-welcome .heading-carousel {
  position: absolute;
  padding: 20px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 4px solid #fff;
  width: 60%;
  bottom: 30%;
  left: 20%;
}
section.section-welcome .welcome-wrapper .owl-carousel-welcome .heading-carousel h1,
section.section-welcome .welcome-wrapper .owl-carousel-welcome .heading-carousel h2 {
  color: #fff;
  font-size: 36px;
  text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.5);
  text-align: center;
}
.searching-content {
  background-color: #006d3a;
  height: 100px;
  display: flex;
  align-items: center;
}
.searching-content .searching-wrapper {
  display: flex;
  justify-content: flex-start;
}
.searching-content .searching-wrapper .search {
  border: 1px solid #fff;
  border-radius: 5px;
  padding: 3px 7px;
  outline: none;
  background: none;
  cursor: pointer;
}
.searching-content .searching-wrapper .search img {
  width: 20px;
}
.searching-content .searching-wrapper input,
.searching-content .searching-wrapper select {
  padding: 0 8px;
  font-size: 14px;
  border: 1px solid #fff;
  border-radius: 5px;
  color: #333;
  width: 20%;
  background: #fff;
  margin-right: 5px;
}
.searching-content .searching-wrapper select:nth-of-type(2) {
  width: 38%;
}
.searching-content .searching-wrapper ::placeholder,
.searching-content .searching-wrapper select {
  color: #888888;
}
section.section-news {
  padding: 150px 0 60px 0;
}
section.section-news .news-wrapper {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-top: 50px;
}
section.section-news .news-wrapper .news-card {
  width: 23%;
  height: 450px;
  padding-bottom: 15px;
  box-shadow: 6px 0px 17px -5px rgba(0, 0, 0, 0.75);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
section.section-news .news-wrapper .news-card:hover .card-description p {
  color: #006d3a;
}
section.section-news .news-wrapper .news-card .card-image {
  position: relative;
  height: 50%;
}
section.section-news .news-wrapper .news-card .card-image img {
  width: 100%;
  height: 95%;
}
section.section-news .news-wrapper .news-card .card-image .date {
  position: absolute;
  bottom: 30px;
  left: 10px;
  background-color: #5cb85c;
  width: 80px;
  border-radius: 3px;
  display: flex;
  justify-content: center;
}
section.section-news .news-wrapper .news-card .card-image .date p {
  font-size: 10px;
  color: #fff;
}
section.section-news .news-wrapper .news-card .card-description {
  padding: 20px 15px;
  height: 40%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
section.section-news .news-wrapper .news-card .card-description p {
  color: #222;
  font-size: 14px;
  text-transform: uppercase;
}
.aktualnosci-section-content {
  width: 75%;
  padding-left: 30px;
}
.aktualnosci-section-content .news-wrapper {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.aktualnosci-section-content .news-wrapper .news-card {
  width: 32%;
  height: 450px;
  padding-bottom: 15px;
  box-shadow: 6px 0px 17px -5px rgba(0, 0, 0, 0.75);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-top: 40px;
}
.aktualnosci-section-content .news-wrapper .news-card:first-of-type,
.aktualnosci-section-content .news-wrapper .news-card:nth-of-type(2),
.aktualnosci-section-content .news-wrapper .news-card:nth-of-type(3) {
  margin-top: 0;
}
.aktualnosci-section-content .news-wrapper .news-card .card-image {
  position: relative;
  height: 50%;
}
.aktualnosci-section-content .news-wrapper .news-card .card-image img {
  width: 100%;
  height: 90%;
}
.aktualnosci-section-content .news-wrapper .news-card .card-image .date {
  position: absolute;
  bottom: 30px;
  left: 10px;
  background-color: #5cb85c;
  width: 80px;
  border-radius: 3px;
  display: flex;
  justify-content: center;
}
.aktualnosci-section-content .news-wrapper .news-card .card-image .date p {
  font-size: 10px;
  color: #fff;
}
.aktualnosci-section-content .news-wrapper .news-card .card-description {
  padding: 20px 15px;
  height: 40%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.aktualnosci-section-content .news-wrapper .news-card .card-description p {
  color: #222;
  font-size: 14px;
  text-transform: uppercase;
}
.aktualnosci-section-content .news-wrapper .box-hidden {
  width: 33%;
  background: transparent;
  visibility: hidden;
}
.aktualnosci-section-content .pagination-list {
  display: flex;
  justify-content: center;
  padding-top: 50px;
}
.aktualnosci-section-content .pagination-list li {
  padding: 5px 10px;
  font-size: 18px;
  transition: all 250ms ease;
}
.aktualnosci-section-content .pagination-list li:hover {
  background-color: #ddd;
}
.aktualnosci-section-content .pagination-list li:hover a {
  color: #fff;
}
.aktualnosci-section-content .pagination-list li a {
  color: #555;
  padding: 5px;
}
.aktualnosci-section-content .pagination-list li.active {
  color: #fff;
  background-color: #006d3a;
  border-radius: 3px;
}
.aktualnosci-section-content .pagination-list li.active a {
  color: #fff;
}
section.section-register {
  background: url(../img/pictures/fotolia_79200372.jpg);
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  padding: 30px 0;
}
section.section-register .register-wrapper {
  display: flex;
  height: 400px;
  justify-content: space-between;
  align-items: center;
}
section.section-register .register-wrapper .register-content {
  height: 300px;
  width: 45%;
  padding: 30px;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}
section.section-register .register-wrapper .register-content .register-description {
  text-align: center;
}
section.section-register .register-wrapper .register-content .register-description h3 {
  font-size: 18px;
  text-transform: uppercase;
}
section.section-register .register-wrapper .register-content .register-description p {
  padding-top: 10px;
  font-size: 15px;
}
section.section-register .register-wrapper .register-content .register-description .newsletter-input {
  padding-top: 10px;
}
section.section-register .register-wrapper .register-content .register-description .newsletter-input input {
  padding: 8px;
  font-size: 14px;
  border: 1px solid #b0b0b0;
  border-radius: 5px;
  background: transparent;
  transition: all 200ms ease-in;
}
section.section-register .register-wrapper .register-content .register-description .newsletter-input ::placeholder {
  color: #555;
  font-size: 14px;
}
section.section-about {
  padding: 80px 0;
}
section.section-about .about-wrapper {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
section.section-about .about-wrapper .about-column {
  width: 33%;
  padding-top: 30px;
}
section.section-about .about-wrapper .about-column img {
  width: 100%;
}
section.section-about .about-wrapper .about-column:nth-of-type(2) {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}
section.section-about .about-wrapper .about-column p {
  font-size: 15px;
}
section.section-about .about-wrapper .about-column p:nth-of-type(2) {
  margin-top: 20px;
}
section.static-page-section-content .content-wrapper {
  display: flex;
  padding: 60px 0;
  flex-wrap: wrap;
}
section.static-page-section-content .content-wrapper .navigation-sidebar {
  width: 25%;
}
section.static-page-section-content .content-wrapper .navigation-sidebar .nav {
  padding-bottom: 50px;
}
section.static-page-section-content .content-wrapper .navigation-sidebar .nav input {
  padding: 8px;
  font-size: 14px;
  border: 1px solid #b0b0b0;
  border-radius: 5px;
  background: transparent;
  transition: all 200ms ease;
  margin-bottom: 20px;
  width: 100%;
}
section.static-page-section-content .content-wrapper .navigation-sidebar .nav ::placeholder {
  color: #555;
  font-size: 14px;
}
section.static-page-section-content .content-wrapper .navigation-sidebar .nav ul li.heading-nav {
  border-radius: 3px;
  padding: 15px;
  background-color: #006d3a;
  border-color: #006d3a;
}
section.static-page-section-content .content-wrapper .navigation-sidebar .nav ul li.heading-nav h3 {
  text-transform: uppercase;
  text-align: left;
  color: #fff;
  font-size: 14px;
}
section.static-page-section-content .content-wrapper .navigation-sidebar .nav ul li a {
  position: relative;
  display: block;
  padding: 10px 30px;
  margin-bottom: -1px;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 3px;
  color: #555;
  font-size: 14px;
  transition: 250ms background-color ease;
}
section.static-page-section-content .content-wrapper .navigation-sidebar .nav ul li a:before {
  content: '';
  position: absolute;
  left: 15px;
  top: 18px;
  background: url(../img/pictures/double-arrow-dark.svg);
  display: block;
  width: 8px;
  height: 8px;
}
section.static-page-section-content .content-wrapper .navigation-sidebar .nav ul li a:hover {
  background-color: #f5f5f5;
}
section.static-page-section-content .content-wrapper .navigation-sidebar .nav ul li:last-of-type :after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  background: #006d3a;
  display: block;
  width: 100%;
  height: 2px;
  border-radius: 3px;
}
section.static-page-section-content .content-wrapper .navigation-sidebar .catalog {
  padding: 8px;
  margin-bottom: 4px;
  background-color: #f5f5f5;
  border: 1px solid #e3e3e3;
  border-radius: 0px;
  display: flex;
  flex-direction: column;
}
section.static-page-section-content .content-wrapper .navigation-sidebar .catalog h3 {
  font-size: 14px;
  color: #333;
  font-weight: 700;
}
section.static-page-section-content .content-wrapper .navigation-sidebar .catalog input,
section.static-page-section-content .content-wrapper .navigation-sidebar .catalog select {
  padding: 8px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background: #fff;
  margin-top: 12px;
}
section.static-page-section-content .content-wrapper .navigation-sidebar .catalog ::placeholder,
section.static-page-section-content .content-wrapper .navigation-sidebar .catalog select {
  color: #555;
}
section.static-page-section-content .content-wrapper .navigation-sidebar .catalog .btn-default {
  align-self: flex-start;
  margin-top: 12px;
}
section.static-page-section-content .content-wrapper .navigation-description {
  width: 75%;
  padding-left: 30px;
}
section.static-page-section-content .content-wrapper .navigation-description .description-content {
  padding-bottom: 50px;
}
section.static-page-section-content .content-wrapper .navigation-description .description-content .heading-subpage p {
  font-size: 20px;
}
section.static-page-section-content .content-wrapper .navigation-description .description-content p {
  margin-top: 20px;
  font-size: 14px;
}
section.static-page-section-content .content-wrapper .navigation-description .description-content a {
  color: #006d3a;
}
section.static-page-section-content .content-wrapper .navigation-description .description-content ul li {
  padding: 5px 0;
  font-size: 14px;
  margin-left: 18px;
}
section.static-page-section-content .content-wrapper .navigation-description .description-content .wrapper {
  padding-bottom: 40px;
  display: flex;
  flex-direction: column;
}
section.static-page-section-content .content-wrapper .navigation-description .description-content .wrapper h4 {
  font-size: 16px;
  line-height: 26px;
  font-weight: 700;
}
section.static-page-section-content .content-wrapper .navigation-description .description-content .wrapper h3 {
  font-size: 16px;
  line-height: 26px;
  text-align: center;
  font-weight: 700;
  text-transform: uppercase;
}
section.static-page-section-content .content-wrapper .navigation-description .description-content .wrapper p {
  text-align: center;
}
section.static-page-section-content .content-wrapper .navigation-description .description-content .wrapper ul {
  padding-top: 20px;
  text-align: center;
}
section.static-page-section-content .content-wrapper .navigation-description .description-content .wrapper ul h4,
section.static-page-section-content .content-wrapper .navigation-description .description-content .wrapper ul p {
  font-size: 16px;
  padding-bottom: 10px;
}
section.static-page-section-content .content-wrapper .navigation-description .description-content .wrapper ul li {
  font-size: 14px;
  padding: 4px 0;
}
section.static-page-section-content .content-wrapper .navigation-description .description-content .wrapper .table {
  display: flex;
  flex-direction: column;
  text-transform: uppercase;
  padding-top: 20px;
  margin-bottom: 0;
}
section.static-page-section-content .content-wrapper .navigation-description .description-content .wrapper .table .table-heading {
  display: flex;
  justify-content: space-between;
}
section.static-page-section-content .content-wrapper .navigation-description .description-content .wrapper .table .table-heading h5 {
  font-size: 15px;
  font-weight: 700;
  padding: 15px 0;
}
section.static-page-section-content .content-wrapper .navigation-description .description-content .wrapper .table .column {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
section.static-page-section-content .content-wrapper .navigation-description .description-content .wrapper .table .column ul {
  border-top: 1px solid #ccc;
  display: flex;
  justify-content: space-between;
  padding: 0;
}
section.static-page-section-content .content-wrapper .navigation-description .description-content .wrapper .table .column ul li {
  padding: 15px 0;
  margin: 0;
}
section.static-page-section-content .content-wrapper .navigation-description .description-content .wrapper .table .note {
  padding-top: 30px;
}
section.static-page-section-content .content-wrapper .navigation-description .description-content .wrapper .table .note p {
  text-align: left;
  text-transform: none;
}
section.static-page-section-content .content-wrapper .navigation-description .description-content table {
  margin: 50px 0;
}
section.static-page-section-content .content-wrapper .navigation-description .description-content table .btn-default-light {
  color: #fff;
  font-size: 14px;
}
section.static-page-section-content .content-wrapper .navigation-description .description-content table tr {
  background-color: #fefefe;
  border-top: 1px solid #ddd;
}
section.static-page-section-content .content-wrapper .navigation-description .description-content table tr:nth-of-type(odd) {
  background-color: #f9f9f9;
}
section.static-page-section-content .content-wrapper .navigation-description .description-content table tr td {
  font-size: 13px;
  color: #333;
  padding: 10px;
}
section.static-page-section-content .content-wrapper .navigation-description .description-content table tr td img {
  width: 50px;
}
section.static-page-section-content .content-wrapper .navigation-description .description-content table tr td:nth-of-type(2),
section.static-page-section-content .content-wrapper .navigation-description .description-content table tr td:nth-of-type(3) {
  padding-top: 25px;
}
section.static-page-section-content .content-wrapper .navigation-description .description-content table tr td:last-of-type {
  padding: 15px 10px 10px 10px;
  text-align: right;
}
section.static-page-section-content .content-wrapper .navigation-description .description-content .management {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}
section.static-page-section-content .content-wrapper .navigation-description .description-content .management:not(:last-of-type) {
  padding-bottom: 40px;
}
section.static-page-section-content .content-wrapper .navigation-description .description-content .management .person {
  width: 33%;
  display: flex;
  align-items: flex-start;
  padding: 15px 0;
}
section.static-page-section-content .content-wrapper .navigation-description .description-content .management .person .personal-data {
  padding-left: 10px;
}
section.static-page-section-content .content-wrapper .navigation-description .description-content .management .person .personal-data p {
  margin: 0;
}
section.static-page-section-content .content-wrapper .navigation-description .description-content .management .person .personal-data p.name {
  font-size: 20px;
  border-bottom: 1.5px solid #006d3a;
}
section.static-page-section-content .content-wrapper .navigation-description .description-content .management .person .personal-data p.position {
  margin-top: 10px;
  font-size: 14px;
}
section.static-page-section-content .content-wrapper .navigation-description .description-content .management .box-hidden {
  width: 33%;
  background: transparent;
  visibility: hidden;
}
section.static-page-section-content .content-wrapper .navigation-description .description-content .content-gallery {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
section.static-page-section-content .content-wrapper .navigation-description .description-content .content-gallery .item {
  width: 32.5%;
  margin: 5px 0;
}
section.static-page-section-content .content-wrapper .navigation-description .description-content .content-gallery .item img {
  height: 100%;
  width: 100%;
}
section.static-page-section-content .content-wrapper .navigation-description .description-content .content-gallery .box-hidden {
  width: 33%;
  background: transparent;
  visibility: hidden;
}
section.static-page-section-content .content-wrapper .navigation-description .avatar {
  display: flex;
  justify-content: center;
  padding-bottom: 50px;
}
section.policy-section-content {
  padding-bottom: 60px;
}
section.policy-section-content .content-wrapper {
  display: flex;
  flex-direction: column;
  padding-top: 60px;
}
section.policy-section-content .content-wrapper .content-description h3 {
  font-size: 16px;
  line-height: 26px;
  margin-bottom: 10px;
}
section.policy-section-content .content-wrapper .content-description p {
  font-size: 15px;
  color: #333;
}
section.policy-section-content .content-wrapper .content-description p:not(first-of-type) {
  margin-top: 10px;
}
section.policy-section-content .content-wrapper .content-description ul {
  list-style-type: decimal;
  padding-left: 30px;
}
section.policy-section-content .content-wrapper .content-description ul li {
  padding: 3px 0;
  font-size: 14px;
}
section.policy-section-content .content-wrapper .content-description ul li ul {
  padding-left: 30px;
}
section.policy-section-content .content-wrapper .content-description ul li a {
  color: #006d3a;
}
section.formularz-zgloszenia-section-content {
  padding-bottom: 60px;
}
section.formularz-zgloszenia-section-content .content-wrapper {
  display: flex;
  flex-direction: column;
}
section.formularz-zgloszenia-section-content .content-wrapper .form-wrapper {
  padding-top: 40px;
}
section.formularz-zgloszenia-section-content .content-wrapper .form-wrapper form .form-row {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 0;
}
section.formularz-zgloszenia-section-content .content-wrapper .form-wrapper form .form-row .w-15 {
  width: 15%;
}
section.formularz-zgloszenia-section-content .content-wrapper .form-wrapper form .form-row .w-72 {
  width: 72%;
}
section.formularz-zgloszenia-section-content .content-wrapper .form-wrapper form .form-row .w-48 {
  width: 48.5%;
}
section.formularz-zgloszenia-section-content .content-wrapper .form-wrapper form .input {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  width: 100%;
}
section.formularz-zgloszenia-section-content .content-wrapper .form-wrapper form .input input,
section.formularz-zgloszenia-section-content .content-wrapper .form-wrapper form .input select {
  padding: 6px;
  font-size: 14px;
  border: 1px solid #ddd;
  border-radius: 5px;
  margin-bottom: 15px;
  width: 100%;
  transition: all 350ms ease-in-out;
}
section.formularz-zgloszenia-section-content .content-wrapper .form-wrapper form .input input:focus,
section.formularz-zgloszenia-section-content .content-wrapper .form-wrapper form .input select:focus {
  outline-color: #006d3a;
  border: 1px solid transparent;
  border-radius: 5px;
}
section.formularz-zgloszenia-section-content .content-wrapper .form-wrapper form .input input {
  background: #fcf8e3;
}
section.formularz-zgloszenia-section-content .content-wrapper .form-wrapper form .input .form-control[disabled] {
  background-color: #eee;
}
section.formularz-zgloszenia-section-content .content-wrapper .form-wrapper form .input label {
  font-size: 14px;
  color: #333;
}
section.formularz-zgloszenia-section-content .content-wrapper .form-wrapper form .input ::placeholder,
section.formularz-zgloszenia-section-content .content-wrapper .form-wrapper form .input select {
  color: #888888;
}
section.formularz-zgloszenia-section-content .content-wrapper .form-wrapper .alert {
  margin-top: 10px;
}
section.formularz-zgloszenia-section-content .content-wrapper .form-wrapper .alert p {
  font-size: 14px;
}
section.formularz-zgloszenia-section-content .content-wrapper .card-body {
  border: none;
}
section.formularz-zgloszenia-section-content .content-wrapper .agreement {
  padding-top: 15px;
}
section.formularz-zgloszenia-section-content .content-wrapper .agreement form {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
section.formularz-zgloszenia-section-content .content-wrapper .agreement form .input-content {
  display: flex;
  align-items: flex-start;
  padding: 5px 0;
}
section.formularz-zgloszenia-section-content .content-wrapper .agreement form .input-content p {
  font-size: 14px;
  margin-top: -4px;
  margin-left: 8px;
}
section.formularz-zgloszenia-section-content .content-wrapper .btn {
  align-self: center;
  margin-top: 20px;
}
section.katalog-section-content {
  padding-bottom: 60px;
}
section.katalog-section-content .content-wrapper {
  padding-top: 60px;
}
section.katalog-section-content .content-wrapper .table-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 30px;
}
section.katalog-section-content .content-wrapper .table-wrapper .table-img {
  width: 28%;
}
section.katalog-section-content .content-wrapper .table-wrapper .table-img img {
  width: 100%;
}
section.katalog-section-content .content-wrapper .table-wrapper .table {
  width: 70%;
  font-size: 14px;
}
section.kontakt-section-content {
  padding-bottom: 60px;
}
section.kontakt-section-content .content-wrapper .form-wrapper {
  padding-top: 40px;
}
section.kontakt-section-content .content-wrapper .form-wrapper form .form-row {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 0;
}
section.kontakt-section-content .content-wrapper .form-wrapper form .form-row .w-48 {
  width: 48.5%;
}
section.kontakt-section-content .content-wrapper .form-wrapper form .input {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}
section.kontakt-section-content .content-wrapper .form-wrapper form .input input,
section.kontakt-section-content .content-wrapper .form-wrapper form .input textarea {
  padding: 6px;
  font-size: 14px;
  border: 1px solid #ddd;
  border-radius: 5px;
  margin-bottom: 15px;
  width: 100%;
  transition: all 200ms ease-in-out;
}
section.kontakt-section-content .content-wrapper .form-wrapper form .input label {
  font-size: 14px;
  color: #333;
}
section.kontakt-section-content .content-wrapper .form-wrapper form .input ::placeholder,
section.kontakt-section-content .content-wrapper .form-wrapper form .input select {
  color: #888888;
}
section.kontakt-section-content .content-wrapper .btn {
  margin-top: 20px;
  padding: 8px 10px;
}
section.kontakt-section-content .content-wrapper .btn svg {
  width: 18px;
  margin-right: 5px;
  height: 100%;
  fill: #fff;
}
section.kontakt-section-content .content-wrapper .btn:hover svg {
  fill: #006d3a;
}
section.kontakt-section-content .content-wrapper .contact-address {
  padding-top: 40px;
}
section.kontakt-section-content .content-wrapper .contact-address .contact-content {
  display: flex;
  align-items: center;
  padding-top: 20px;
}
section.kontakt-section-content .content-wrapper .contact-address .contact-content .column {
  width: 50%;
}
section.kontakt-section-content .content-wrapper .contact-address .contact-content .column h3 {
  font-size: 24px;
  margin-bottom: 10px;
}
section.kontakt-section-content .content-wrapper .contact-address .contact-content .column p {
  margin-top: 10px;
  font-size: 18px;
}
section.kontakt-section-content .content-wrapper .contact-person {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
section.kontakt-section-content .content-wrapper .contact-person:not(:last-of-type) {
  padding-bottom: 40px;
}
section.kontakt-section-content .content-wrapper .contact-person .person {
  width: 47%;
  display: flex;
  align-items: flex-start;
  padding: 15px 0;
}
section.kontakt-section-content .content-wrapper .contact-person .person .personal-data {
  padding-left: 10px;
}
section.kontakt-section-content .content-wrapper .contact-person .person .personal-data p {
  margin-top: 5px;
}
section.kontakt-section-content .content-wrapper .contact-person .person .personal-data p.name {
  font-size: 24px;
  border-bottom: 1.5px solid #006d3a;
  width: 300px;
}
section.kontakt-section-content .content-wrapper .contact-person .person .personal-data p.position {
  font-size: 18px;
}
section.kontakt-section-content .content-wrapper .contact-person .person .personal-data p.task a {
  color: #006d3a;
}
section.section-footer {
  padding: 30px 0;
  background-color: #4b4b4b;
  position: relative;
}
section.section-footer .footer-flexbox {
  position: relative;
  padding-top: 50px;
  display: flex;
  justify-content: space-between;
}
section.section-footer .footer-flexbox:before {
  content: '';
  display: block;
  position: absolute;
  width: 100%;
  top: -30px;
  height: 4px;
  background-color: #006d3a;
}
section.section-footer .footer-flexbox .flexbox-service {
  width: 33%;
}
section.section-footer .footer-flexbox .flexbox-service:not(:first-of-type) {
  padding-left: 40px;
}
section.section-footer .footer-flexbox .flexbox-service .logo {
  height: 100px;
}
section.section-footer .footer-flexbox .flexbox-service .social-media-icon {
  margin-top: 10px;
  width: 40px;
  transition: 200ms transform ease;
}
section.section-footer .footer-flexbox .flexbox-service .social-media-icon:hover {
  transform: scale(1.05);
}
section.section-footer .footer-flexbox .flexbox-service p {
  margin-top: 10px;
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;
  color: #fff;
}
section.section-footer .footer-flexbox .flexbox-service h3 {
  margin-left: -15px;
}
section.section-footer .footer-flexbox .flexbox-service .footer-heading {
  margin-top: 0;
  padding-bottom: 10px;
  font-size: 18px;
  color: #fcfcfc;
}
section.section-footer .footer-flexbox .flexbox-service ul li {
  position: relative;
  margin-top: 10px;
  color: #fff;
  font-size: 14px;
}
section.section-footer .footer-flexbox .flexbox-service ul li:before {
  content: '';
  position: absolute;
  left: -15px;
  top: 6px;
  background: url(../img/pictures/double-arrow.svg);
  display: block;
  width: 10px;
  height: 10px;
}
section.section-footer .footer-flexbox .flexbox-service ul li a {
  color: #fff;
  font-size: 13px;
  text-transform: uppercase;
}
section.section-footer .footer-flexbox .flexbox-service ul li a:hover {
  color: #000;
}
section.section-footer .footer-flexbox .flexbox-service.contact h3 {
  margin-left: 10px;
}
section.section-footer .footer-flexbox .flexbox-service.contact p {
  margin: 0 0 5px 0;
}
section.section-footer .footer-flexbox .flexbox-service.contact .md-font-size {
  font-size: 18px;
  line-height: 24px;
}
section.section-footer .footer-flexbox .flexbox-service.contact ul .contact-wrapper {
  display: flex;
  align-items: flex-start;
}
section.section-footer .footer-flexbox .flexbox-service.contact ul .contact-wrapper img {
  width: 32px;
  margin: 8px 10px 0 0;
}
section.section-footer .footer-flexbox .flexbox-service.contact ul li {
  margin-top: 0;
  padding-bottom: 8px;
}
section.section-footer .footer-flexbox .flexbox-service.contact ul li:before {
  display: none;
}
section.section-footer .footer-flexbox .flexbox-service.contact ul li a {
  font-size: 18px;
  text-transform: lowercase;
}
section.section-footer .footer-flexbox .flexbox-service.contact ul li a:hover {
  color: #006d3a;
}
section.section-footer .footer-credits {
  border-top: 1px solid rgba(255, 255, 255, 0.2);
  margin-top: 50px;
  padding-top: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}
section.section-footer .footer-credits p {
  font-size: 13px;
  color: #fff;
  font-weight: 300;
}
section.section-footer .footer-credits p a {
  color: #fff;
}
section.section-footer .alert {
  position: relative;
  margin: 50px 0 0 0;
  padding: 50px 0 0 0;
  border-radius: 0;
  background: none;
  border: none;
  border-top: 1px solid rgba(255, 255, 255, 0.2);
}
section.section-footer .alert p {
  font-size: 13px;
  color: #fff;
  font-weight: 300;
}
section.section-footer .alert p a {
  color: #fcfcfc;
  cursor: pointer;
  transition: all 0.3s;
}
section.section-footer .alert p a:hover {
  color: #006d3a;
}
@media (max-width: 1199.98px) {
  nav.section-header .header-flexbox .wrapper .flexbox-nav-wrapper .header-bottom .flexbox-nav li {
    padding: 0 8px;
  }
  nav.section-header .header-flexbox .wrapper .flexbox-nav-wrapper .header-bottom .flexbox-nav li a {
    font-size: 13px;
  }
}
@media (max-width: 991.98px) {
  nav.section-header .header-flexbox .logo-wrapper .flexbox-logo img {
    width: 100%;
  }
  nav.section-header .header-flexbox .wrapper .header-top .top-wrapper .input-content input {
    width: 180px;
  }
  nav.section-header .header-flexbox .wrapper .header-top .top-wrapper .btn-default {
    width: 150px;
    font-size: 12px;
    padding: 8px 0;
  }
  nav.section-header .header-flexbox .wrapper .flexbox-nav-wrapper .header-bottom .flexbox-nav li {
    padding: 0 4px;
  }
  nav.section-header .header-flexbox .wrapper .flexbox-nav-wrapper .header-bottom .flexbox-nav li a {
    font-size: 11px;
  }
  section.section-news .news-wrapper .news-card {
    width: 48%;
    height: 400px;
    margin: 10px 5px;
  }
  section.section-register .register-wrapper .register-content {
    width: 48%;
    padding: 20px;
  }
  section.section-register .register-wrapper .register-content img {
    width: 60px;
  }
  section.section-register .register-wrapper .register-content .register-description {
    margin-top: 10px;
  }
  section.section-register .register-wrapper .register-content .register-description p {
    font-size: 13px;
    padding: 10px 0;
  }
  section.section-register .register-wrapper .register-content .register-description .btn-default {
    margin-top: 10px;
  }
  section.section-about .about-wrapper {
    flex-direction: column;
  }
  section.section-about .about-wrapper .about-column {
    width: 100%;
  }
  section.section-about .about-wrapper .about-column .btn-default {
    margin-top: 30px;
  }
  section.section-about .about-wrapper .about-column:first-of-type {
    height: 300px;
    width: 100%;
  }
  section.section-about .about-wrapper .about-column:first-of-type img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  section.section-about .about-wrapper .about-column:nth-of-type(3) {
    display: none;
  }
  section.static-page-section-content .content-wrapper {
    display: flex;
    padding: 60px 0;
    flex-wrap: wrap;
  }
  section.static-page-section-content .content-wrapper .navigation-sidebar {
    width: 100%;
  }
  section.static-page-section-content .content-wrapper .navigation-sidebar .nav ul {
    width: 100%;
  }
  section.static-page-section-content .content-wrapper .navigation-description {
    width: 100%;
    padding-left: 0;
    padding-top: 30px;
  }
  section.static-page-section-content .content-wrapper .aktualnosci-section-content {
    width: 100%;
    padding-left: 0;
    padding-top: 30px;
  }
  section.static-page-section-content .content-wrapper .aktualnosci-section-content .news-wrapper .news-card {
    width: 48%;
    height: 400px;
    margin: 10px 5px;
  }
  section.section-footer .footer-flexbox {
    flex-direction: column;
    align-items: center;
    padding-top: 0;
  }
  section.section-footer .footer-flexbox .flexbox-service {
    width: 80%;
    padding-top: 30px;
  }
  section.section-footer .footer-flexbox .flexbox-service:not(:first-of-type) {
    padding-left: 0;
  }
  section.section-footer .footer-flexbox .flexbox-service.contact {
    margin-left: -50px;
  }
}
@media (max-width: 767.98px) {
  nav.section-header .header-flexbox {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  nav.section-header .header-flexbox .logo-wrapper {
    display: flex;
    justify-content: center;
    width: 60%;
  }
  nav.section-header .header-flexbox .logo-wrapper .flexbox-logo img {
    width: 80%;
  }
  nav.section-header .header-flexbox .nav-button {
    display: flex;
    margin-right: -10px;
  }
  nav.section-header .header-flexbox .wrapper {
    flex-direction: row;
    margin-top: 15px;
  }
  nav.section-header .header-flexbox .wrapper .header-top {
    border-top: 1px solid #fff;
    padding-top: 10px;
    padding-bottom: 0;
  }
  nav.section-header .header-flexbox .wrapper .header-top .top-wrapper {
    justify-content: center;
  }
  nav.section-header .header-flexbox .wrapper .header-top .top-wrapper .input-content {
    margin-right: 10px;
  }
  nav.section-header .header-flexbox .wrapper .header-top .top-wrapper .input-content form {
    display: flex;
  }
  nav.section-header .header-flexbox .wrapper .header-top .top-wrapper .input-content input {
    width: 140px;
  }
  nav.section-header .header-flexbox .wrapper .header-top .top-wrapper .btn-default {
    width: 150px;
    font-size: 12px;
    padding: 8px 0;
  }
  nav.section-header .header-flexbox .wrapper .flexbox-nav-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    overflow-y: scroll;
    background: #fff;
    z-index: 555;
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s;
  }
  nav.section-header .header-flexbox .wrapper .flexbox-nav-wrapper .nav-button-close {
    display: block;
  }
  nav.section-header .header-flexbox .wrapper .flexbox-nav-wrapper .header-bottom {
    height: 100%;
    padding: 50px;
  }
  nav.section-header .header-flexbox .wrapper .flexbox-nav-wrapper .header-bottom .flexbox-nav {
    height: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  nav.section-header .header-flexbox .wrapper .flexbox-nav-wrapper .header-bottom .flexbox-nav li {
    padding: 12px 0;
  }
  nav.section-header .header-flexbox .wrapper .flexbox-nav-wrapper .header-bottom .flexbox-nav li a {
    font-size: 18px;
    color: #333;
  }
  nav.section-header .header-flexbox .wrapper .flexbox-nav-wrapper .flexbox-nav-inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 100%;
    padding: 50px;
    margin-right: 0;
  }
  nav.section-header .header-flexbox .wrapper .flexbox-nav-wrapper.active {
    opacity: 1;
    visibility: visible;
  }
  .header-background {
    height: 280px;
    padding-top: 180px;
    margin-top: -170px;
  }
  .header-background h1 {
    font-size: 36px;
  }
  .searching-content {
    padding: 20px 0;
    height: auto;
  }
  .searching-content .searching-wrapper {
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
  }
  .searching-content .searching-wrapper input,
  .searching-content .searching-wrapper select {
    width: 100%;
    margin: 5px 0;
    padding: 8px;
  }
  .searching-content .searching-wrapper input:nth-of-type(2),
  .searching-content .searching-wrapper select:nth-of-type(2) {
    width: 100%;
  }
  .searching-content .searching-wrapper .btn-border {
    margin-top: 15px;
  }
  section.section-welcome {
    margin-top: -170px;
  }
  section.section-welcome .welcome-wrapper .owl-carousel-welcome .heading-carousel {
    width: 75%;
    bottom: 25%;
    left: 15%;
    padding: 20px;
  }
  section.section-welcome .welcome-wrapper .owl-carousel-welcome .heading-carousel h1,
  section.section-welcome .welcome-wrapper .owl-carousel-welcome .heading-carousel h2 {
    font-size: 30px;
  }
  section.section-news {
    padding: 280px 0 60px 0;
  }
  section.static-page-section-content .content-wrapper .navigation-description .description-content .management .person {
    width: 48%;
    align-items: center;
  }
  section.formularz-zgloszenia-section-content .content-wrapper .form-wrapper form .form-row {
    flex-direction: column;
  }
  section.formularz-zgloszenia-section-content .content-wrapper .form-wrapper form .input {
    width: 100% !important;
  }
  section.formularz-zgloszenia-section-content .content-wrapper .agreement form .input-content p {
    font-size: 13px;
  }
  section.katalog-section-content {
    padding-bottom: 60px;
  }
  section.katalog-section-content .content-wrapper .table-wrapper {
    flex-direction: column;
  }
  section.katalog-section-content .content-wrapper .table-wrapper .table-img {
    width: 70%;
    margin-bottom: 20px;
  }
  section.katalog-section-content .content-wrapper .table-wrapper .table-img img {
    width: 100%;
  }
  section.katalog-section-content .content-wrapper .table-wrapper .table {
    width: 100%;
  }
  section.kontakt-section-content .content-wrapper .contact-address .heading-subpage {
    margin-bottom: 0;
  }
  section.kontakt-section-content .content-wrapper .contact-address .contact-content {
    flex-direction: column;
  }
  section.kontakt-section-content .content-wrapper .contact-address .contact-content .column {
    width: 100%;
    margin-top: 20px;
  }
  section.kontakt-section-content .content-wrapper .contact-address .contact-content .column h3 {
    font-size: 22px;
  }
  section.kontakt-section-content .content-wrapper .contact-address .contact-content .column p {
    font-size: 16px;
  }
  section.kontakt-section-content .content-wrapper .contact-person {
    flex-direction: column;
  }
  section.kontakt-section-content .content-wrapper .contact-person .person {
    width: 100%;
  }
}
@media (max-width: 550.98px) {
  nav.section-header .header-flexbox .logo-wrapper {
    width: 100%;
  }
  nav.section-header .header-flexbox .logo-wrapper .flexbox-logo img {
    width: 80%;
  }
  nav.section-header .header-flexbox .wrapper {
    width: 100%;
  }
  nav.section-header .header-flexbox .wrapper .header-top {
    width: 100%;
  }
  nav.section-header .header-flexbox .wrapper .header-top .top-wrapper {
    width: 100%;
  }
  nav.section-header .header-flexbox .wrapper .header-top .top-wrapper .btn-default {
    width: 150px;
    font-size: 12px;
    padding: 10px 5px;
  }
  nav.section-header .header-flexbox .wrapper .header-top .top-wrapper form {
    margin-right: 5px;
  }
  nav.section-header .header-flexbox .wrapper .header-top .top-wrapper form input {
    width: 135px;
  }
  .header-background {
    height: 300px;
    padding-top: 190px;
    margin-top: -200px;
  }
  .header-background h1 {
    font-size: 32px;
  }
  section.section-welcome {
    margin-top: -200px;
  }
  section.section-welcome .welcome-wrapper .owl-carousel-welcome .heading-carousel {
    width: 70%;
    left: 15%;
    padding: 20px;
  }
  section.section-welcome .welcome-wrapper .owl-carousel-welcome .heading-carousel h1,
  section.section-welcome .welcome-wrapper .owl-carousel-welcome .heading-carousel h2 {
    font-size: 22px;
  }
  section.section-news .news-wrapper .news-card {
    width: 90%;
    margin: 15px auto;
  }
  section.section-news .news-wrapper .news-card .card-image {
    width: 100%;
  }
  section.section-news .news-wrapper .news-card .card-image img {
    object-fit: cover;
    object-position: center 20%;
  }
  section.section-news .news-wrapper .news-card .card-description {
    text-align: center;
  }
  section.section-register .register-wrapper {
    height: auto;
    flex-direction: column;
  }
  section.section-register .register-wrapper .register-content {
    width: 90%;
    padding: 20px;
    margin-top: 15px;
  }
  section.section-register .register-wrapper .register-content img {
    width: 60px;
  }
  section.section-register .register-wrapper .register-content .register-description {
    margin-top: 10px;
  }
  section.section-register .register-wrapper .register-content .register-description p {
    font-size: 13px;
    padding: 10px 0;
  }
  section.section-register .register-wrapper .register-content .register-description .btn-default {
    margin-top: 0;
  }
  section.section-about {
    padding: 60px 0;
  }
  section.section-about .about-wrapper .about-column {
    text-align: center;
  }
  section.section-about .about-wrapper .about-column:first-of-type,
  section.section-about .about-wrapper .about-column:nth-of-type(3) {
    display: none;
  }
  section.static-page-section-content .content-wrapper {
    padding: 30px 0 60px 0;
  }
  section.static-page-section-content .content-wrapper .aktualnosci-section-content .news-wrapper .news-card {
    width: 90%;
    margin: 15px auto;
  }
  section.static-page-section-content .content-wrapper .aktualnosci-section-content .news-wrapper .news-card .card-image {
    width: 100%;
  }
  section.static-page-section-content .content-wrapper .aktualnosci-section-content .news-wrapper .news-card .card-image img {
    object-fit: cover;
    object-position: center 20%;
  }
  section.static-page-section-content .content-wrapper .aktualnosci-section-content .news-wrapper .news-card .card-description {
    text-align: center;
  }
  section.static-page-section-content .content-wrapper .navigation-description .description-content {
    text-align: center;
    padding-bottom: 30px;
  }
  section.static-page-section-content .content-wrapper .navigation-description .description-content ul {
    text-align: left;
  }
  section.static-page-section-content .content-wrapper .navigation-description .description-content .management {
    text-align: left;
  }
  section.static-page-section-content .content-wrapper .navigation-description .description-content .management .person {
    width: 80%;
    margin: 0 auto;
  }
  section.static-page-section-content .content-wrapper .navigation-description .description-content .management .box-hidden {
    width: 52%;
  }
  section.static-page-section-content .content-wrapper .navigation-description .description-content table tr td {
    text-align: left;
    line-height: 18px;
  }
  section.static-page-section-content .content-wrapper .navigation-description .description-content table tr td:nth-of-type(3) {
    display: none;
  }
  section.static-page-section-content .content-wrapper .navigation-description .description-content .wrapper {
    padding-bottom: 0;
  }
  section.static-page-section-content .content-wrapper .navigation-description .description-content .wrapper h4 {
    margin-top: 20px;
  }
  section.static-page-section-content .content-wrapper .navigation-description .description-content .wrapper .table {
    padding-top: 10px;
  }
  section.static-page-section-content .content-wrapper .navigation-description .description-content .wrapper .table .column ul li {
    font-size: 12px;
    width: 50%;
    text-align: left;
  }
  section.static-page-section-content .content-wrapper .navigation-description .description-content .wrapper .table .column ul li:nth-of-type(2) {
    text-align: right;
  }
  section.static-page-section-content .content-wrapper .navigation-description .description-content .wrapper .table .note {
    padding-top: 0;
  }
  section.static-page-section-content .content-wrapper .navigation-description .description-content .content-gallery .item {
    width: 48.5%;
  }
}
@media (max-width: 450px) {
  section.section-register .register-wrapper .register-content .register-description .btn-default {
    margin-top: 10px;
  }
  section.katalog-section-content .content-wrapper .table-wrapper .table td {
    padding: 10px 0;
  }
}

// Large devices (desktops, less than 1200px)
@media (max-width: 1199.98px) {

	nav.section-header {
		.header-flexbox {
			.wrapper {
				.flexbox-nav-wrapper {
					.header-bottom {
						.flexbox-nav {
							li {
								padding: 0 8px;

								a {
									font-size: 13px;
								}
							}
						}
					}
				}
			}
		}
	}
}

// Medium devices (tablets, less than 992px)
@media (max-width: 991.98px) {

	nav.section-header {
		.header-flexbox {
			.logo-wrapper {
				.flexbox-logo {
					img {
						width: 100%;
					}
				}
			}

			.wrapper {
				.header-top {
					.top-wrapper {
						.input-content {
							input {
								width: 180px;
							}
						}

						.btn-default {
							width: 150px;
							font-size: 12px;
							padding: 8px 0;
						}
					}
				}

				.flexbox-nav-wrapper {
					.header-bottom {
						.flexbox-nav {
							li {
								padding: 0 4px;

								a {
									font-size: 11px;
								}
							}
						}
					}
				}
			}
		}
	}

	section.section-news {
		.news-wrapper {
			.news-card {
				width: 48%;
				height: 400px;
				margin: 10px 5px;
			}
		}
	}

	section.section-register {
		.register-wrapper {
			.register-content {
				width: 48%;
				padding: 20px;

				img {
					width: 60px;
				}

				.register-description {
					margin-top: 10px;

					p {
						font-size: 13px;
						padding: 10px 0;
					}

					.btn-default {
						margin-top: 10px;
					}
				}
			}
		}
	}

	section.section-about {
		.about-wrapper {
			flex-direction: column;

			.about-column {
				width: 100%;

				.btn-default {
					margin-top: 30px;
				}

				&:first-of-type {
					height: 300px;
					width: 100%;

					img {
						width: 100%;
						height: 100%;
						object-fit: contain;
					}
				}

				&:nth-of-type(3) {
					display: none;
				}
			}
		}
	}

	section.static-page-section-content {
		.content-wrapper {
			display: flex;
			padding: 60px 0;
			flex-wrap: wrap;

			.navigation-sidebar {
				width: 100%;

				.nav {
					ul {
						width: 100%;
					}
				}
			}

			.navigation-description {
				width: 100%;
				padding-left: 0;
				padding-top: 30px;
			}

			.aktualnosci-section-content {
				width: 100%;
				padding-left: 0;
				padding-top: 30px;

				.news-wrapper {
					.news-card {
						width: 48%;
						height: 400px;
						margin: 10px 5px;
					}
				}
			}
		}
	}

	section.section-footer {
		.footer-flexbox {
			flex-direction: column;
			align-items: center;
			padding-top: 0;

			.flexbox-service {
				width: 80%;
				padding-top: 30px;

				&:not(:first-of-type) {
					padding-left: 0;
				}

				&.contact {
					margin-left: -50px;
				}
			}
		}
	}
}

// Small devices (landscape phones, less than 768px)
@media (max-width: 767.98px) {
	nav.section-header {
		.header-flexbox {
			display: flex;
			flex-direction: column;
			align-items: center;

			.logo-wrapper {
				display: flex;
				justify-content: center;
				width: 60%;

				.flexbox-logo {
					img {
						width: 80%;
					}
				}
			}

			.nav-button {
				display: flex;
				margin-right: -10px;
			}

			.wrapper {
				flex-direction: row;
				margin-top: 15px;

				.header-top {
					border-top: 1px solid #fff;
					padding-top: 10px;
					padding-bottom: 0;

					.top-wrapper {
						justify-content: center;

						.input-content {
							margin-right: 10px;

							form {
								display: flex;
							}

							input {
								width: 140px;
							}
						}

						.btn-default {
							width: 150px;
							font-size: 12px;
							padding: 8px 0;
						}
					}
				}

				.flexbox-nav-wrapper {
					position: fixed;
					top: 0;
					left: 0;
					height: 100%;
					width: 100%;
					overflow-y: scroll;
					background: #fff;
					z-index: 555;
					opacity: 0;
					visibility: hidden;
					transition: all 0.3s;

					.nav-button-close {
						display: block;
					}

					.header-bottom {
						height: 100%;
						padding: 50px;

						.flexbox-nav {
							height: 100%;
							flex-direction: column;
							align-items: center;
							justify-content: center;

							li {
								padding: 12px 0;

								a {
									font-size: 18px;
									color: #333;
								}
							}
						}
					}

					.flexbox-nav-inner {
						display: flex;
						flex-direction: column;
						justify-content: center;
						min-height: 100%;
						padding: 50px;
						margin-right: 0;
					}

					&.active {
						opacity: 1;
						visibility: visible;
					}
				}
			}
		}
	}

	.header-background {
		height: 280px;
		padding-top: 180px;
		margin-top: -170px;

		h1 {
			font-size: 36px;
		}
	}

	.searching-content {
		padding: 20px 0;
		height: auto;

		.searching-wrapper {
			flex-direction: column;
			align-items: flex-start;
			justify-content: space-between;

			input,
			select {
				width: 100%;
				margin: 5px 0;
				padding: 8px;

				&:nth-of-type(2) {
					width: 100%;
				}
			}

			.btn-border {
				margin-top: 15px;
			}
		}
	}

	section.section-welcome {
		margin-top: -170px;

		.welcome-wrapper {
			.owl-carousel-welcome {
				.heading-carousel {
					width: 75%;
					bottom: 25%;
					left: 15%;
					padding: 20px;

					h1,
					h2 {
						font-size: 30px;
					}
				}
			}
		}
	}

	section.section-news {
		padding: 280px 0 60px 0;
	}

	section.static-page-section-content {
		.content-wrapper {
			.navigation-description {
				.description-content {
					.management {
						.person {
							width: 48%;
							align-items: center;
						}
					}
				}
			}
		}
	}

	section.formularz-zgloszenia-section-content {
		.content-wrapper {
			.form-wrapper {
				form {
					.form-row {
						flex-direction: column;
					}

					.input {
						width: 100% !important;
					}
				}
			}

			.agreement {
				form {
					.input-content {
						p {
							font-size: 13px;
						}
					}
				}
			}
		}
	}


	section.katalog-section-content {
		padding-bottom: 60px;

		.content-wrapper {
			.table-wrapper {
				flex-direction: column;

				.table-img {
					width: 70%;
					margin-bottom: 20px;

					img {
						width: 100%;
					}
				}

				.table {
					width: 100%;
				}
			}
		}
	}

	section.kontakt-section-content {
		.content-wrapper {
			.contact-address {
				.heading-subpage {
					margin-bottom: 0;
				}

				.contact-content {
					flex-direction: column;

					.column {
						width: 100%;
						margin-top: 20px;

						h3 {
							font-size: 22px;
						}

						p {
							font-size: 16px;
						}
					}
				}
			}

			.contact-person {
				flex-direction: column;

				.person {
					width: 100%;
				}
			}
		}
	}
}

// Extra small devices (portrait phones, less than 576px)
@media (max-width: 550.98px) {

	nav.section-header {
		.header-flexbox {
			.logo-wrapper {
				width: 100%;

				.flexbox-logo {
					img {
						width: 80%;
					}
				}
			}

			.wrapper {
				width: 100%;

				.header-top {
					width: 100%;

					.top-wrapper {
						width: 100%;

						.btn-default {
							width: 150px;
							font-size: 12px;
							padding: 10px 5px;
						}

						form {
							margin-right: 5px;

							input {
								width: 135px;
							}
						}

						// .social-media {
						// 	display: none;
						// }
					}
				}
			}
		}
	}

	.header-background {
		height: 300px;
		padding-top: 190px;
		margin-top: -200px;

		h1 {
			font-size: 32px;
		}
	}

	section.section-welcome {
		margin-top: -200px;

		.welcome-wrapper {
			.owl-carousel-welcome {
				.heading-carousel {
					width: 70%;
					left: 15%;
					padding: 20px;

					h1,
					h2 {
						font-size: 22px;
					}
				}
			}
		}
	}

	section.section-news {
		.news-wrapper {
			.news-card {
				width: 90%;
				margin: 15px auto;

				.card-image {
					width: 100%;

					img {
						object-fit: cover;
						object-position: center 20%;
					}
				}

				.card-description {
					text-align: center;
				}
			}
		}
	}

	section.section-register {
		.register-wrapper {
			height: auto;
			flex-direction: column;

			.register-content {
				width: 90%;
				padding: 20px;
				margin-top: 15px;

				img {
					width: 60px;
				}

				.register-description {
					margin-top: 10px;

					p {
						font-size: 13px;
						padding: 10px 0;
					}

					.btn-default {
						margin-top: 0;
					}
				}
			}
		}
	}

	section.section-about {
		padding: 60px 0;

		.about-wrapper {
			.about-column {
				text-align: center;

				&:first-of-type,
				&:nth-of-type(3) {
					display: none;
				}
			}
		}
	}

	section.static-page-section-content {
		.content-wrapper {
			padding: 30px 0 60px 0;

			.aktualnosci-section-content {
				.news-wrapper {
					.news-card {
						width: 90%;
						margin: 15px auto;

						.card-image {
							width: 100%;

							img {
								object-fit: cover;
								object-position: center 20%;
							}
						}

						.card-description {
							text-align: center;
						}
					}
				}
			}

			.navigation-description {
				.description-content {
					text-align: center;
					padding-bottom: 30px;

					ul {
						text-align: left;
					}

					.management {
						text-align: left;

						.person {
							width: 80%;
							margin: 0 auto;
						}

						.box-hidden {
							width: 52%;
						}
					}

					table {
						tr {
							td {
								text-align: left;
								line-height: 18px;

								&:nth-of-type(3) {
									display: none;
								}
							}
						}
					}

					.wrapper {
						padding-bottom: 0;

						h4 {
							margin-top: 20px;
						}

						.table {
							padding-top: 10px;

							.column {
								ul {
									li {
										font-size: 12px;
										width: 50%;
										text-align: left;

										&:nth-of-type(2) {
											text-align: right;
										}
									}
								}
							}

							.note {
								padding-top: 0;
							}
						}
					}

					.content-gallery {
						.item {
							width: 48.5%;
						}
					}
				}
			}
		}
	}
}

// Extra small devices (portrait phones, less than 576px)
@media (max-width: 450px) {

	section.section-register {
		.register-wrapper {
			.register-content {
				.register-description {
					.btn-default {
						margin-top: 10px;
					}
				}
			}
		}
	}

	section.katalog-section-content {
		.content-wrapper {
			.table-wrapper {
				.table {
					td {
						padding: 10px 0;
					}
				}
			}
		}
	}
}
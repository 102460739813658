@import url('https://fonts.googleapis.com/css?family=Noto+Serif:400,700&display=swap&subset=latin-ext');

body {
	min-height: 100vh;
	height: 100%;
	font-family: 'Noto Serif', serif;
	display: flex;
	flex-direction: column;
	justify-content: space-between;

	&.no-scroll {
		overflow: hidden;
		max-height: 100vh;
	}

	ul,
	ol,
	p,
	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		margin: 0;
		padding: 0;
	}

	a {
		text-decoration: none !important;
	}

	input,
	select,
	textarea {
		outline-color: transparent;

		&:focus {
			outline-color: @color1;
			border: 1px solid transparent;
			border-radius: 5px;
			box-shadow: 0px 0px 10px rgba(@color1, 0.3);
		}
	}
}

@color1: #006d3a;

.alert-wrapper {
	position: fixed;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	overflow-y: scroll;
	background: rgba(0, 0, 0, 0.2);
	z-index: 555;
	opacity: 0;
	visibility: hidden;
	transition: all 0.4s;

	.alert-service {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		min-height: 100vh;
		width: 100%;
		transform: translateX(8px);
		padding: 50px 0;

		.service-inner {
			padding: 50px;
			background: #fff;
			width: 100%;
			box-shadow: 0px 0px 32px 1px rgba(0, 0, 0, 0.15);
			text-align: center;
			border-top: 5px solid #fff;
			position: relative;
			transform: scale(0.8);
			transition: all 0.5s;

			.inner-dismiss {
				top: 10px;
				right: 10px;
				position: absolute;
				border: 0;
				background: none;
				padding: 0;
				cursor: pointer;
				display: flex;
				padding: 5px;
				outline: none;

				img {
					height: 15px;
				}
			}

			&.success {
				border-top: 5px solid #78b042;
			}

			&.warning {
				border-top: 5px solid #d51a27;
			}

			&.info {
				border-top: 5px solid #1a88d5;
			}
		}
	}

	&.active {
		opacity: 1;
		visibility: visible;

		.alert-service {
			.service-inner {
				transform: none;
			}
		}
	}
}

.modal-service-wrapper {
	position: fixed;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	overflow-y: scroll;
	background: rgba(0, 0, 0, 0.5);
	z-index: 555;
	opacity: 0;
	visibility: hidden;
	transition: all 0.3s;

	&.active {
		opacity: 1;
		visibility: visible;
	}

	.modal-service {
		padding: 50px;
		position: relative;
		display: flex;
		justify-content: center;
		align-items: center;
		min-height: 100%;

		.service-inner {
			background: #fff;
			width: 700px;
			max-width: 100%;
			padding: 50px 25px 25px 25px;
			text-align: center;
			position: relative;

			.service-button {
				position: absolute;
				top: 20px;
				right: 20px;
				border: none;
				background: none;
				padding: 15px;
				display: flex;
				justify-content: center;
				align-items: center;

				.button-bar {
					display: block;
					position: absolute;
					width: 20px;
					height: 2px;
					background: #222;

					&:nth-child(1) {
						transform: rotate(45deg);
					}

					&:nth-child(2) {
						transform: rotate(-45deg);
					}
				}
			}

			.service-content {
				.heading {
					font-size: 16px;
					font-weight: 700;
					text-transform: uppercase;
					margin-bottom: 20px;
				}

				.btn {
					display: flex;
					justify-content: center;
					margin-top: 5px;
				}
			}

			.service-buttons {
				margin-top: 30px;
				display: flex;
				justify-content: space-between;
				align-items: center;

				.btn-link {
					padding: 0;
					text-transform: uppercase;
					font-weight: 700;
					text-decoration: none;
				}
			}
		}
	}
}

.btn {
	border: none;
	background: none;
	cursor: pointer;
	padding: 8px 17px;

	&.btn-default {
		background-color: @color1;
		transition: background-color 250ms ease;
		color: #fff;
		font-size: 14px;

		&:hover {
			background-color: #fff;
			color: @color1;
		}
	}

	&.btn-border {
		border: 1px solid rgba(#fff, 0.7);
		text-transform: lowercase;
		transition: border 250ms ease;
		color: #fff;

		&:hover {
			border: 1px solid #fff;
		}
	}

	&.btn-default-border {
		border: 1px solid transparent;

		&:hover {
			border: 1px solid @color1;
		}
	}

	&.btn-default-light {
		color: #fff;
		background-color: rgba(#5cb85c, 0.8);

		&:hover {
			background-color: #5cb85c;
		}
	}
}

.header-background {
	background: url(../img/pictures/fotolia_79200372.jpg);
	background-attachment: fixed;
	background-size: cover;
	background-position: bottom;
	padding-top: 160px;
	padding-bottom: 2em;
	margin-top: -150px;
	height: 260px;
	z-index: 10;

	h1 {
		color: #fff;
		margin-top: 20px;
		text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.5);
	}
}

.heading {
	display: flex;
	flex-direction: column;
	align-items: center;
	text-align: center;

	h1,
	h2 {
		color: #333;
		font-size: 42px;
		line-height: 55px;
		margin-bottom: 20px;
	}

	p {
		width: 80%;
		margin-top: 20px;
		text-align: center;
		font-size: 20px;
	}
}

.heading-subpage {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-bottom: 30px;
	text-align: center;

	h1,
	h2 {
		color: #333;
		font-size: 28px;
		line-height: 45px;
		margin-bottom: 10px;
	}

	p {
		width: 90%;
		margin-top: 10px;
		text-align: center;
		font-size: 20px;
		line-height: 26px;
	}
}

nav.section-header {
	background: url(../img/backgrounds/dark-menu.png) repeat;
	position: relative;
	opacity: 0.9;
	z-index: 22;

	.header-flexbox {
		padding: 18px 0;
		display: flex;
		align-items: center;
		justify-content: space-between;

		.logo-wrapper {
			width: 25%;

			.flexbox-logo {
				img {
					width: 260px;
				}
			}
		}

		.wrapper {
			display: flex;
			flex-direction: column;

			.header-top {
				padding-bottom: 30px;
				display: flex;
				justify-content: flex-end;

				.top-wrapper {
					display: flex;
					align-items: center;
					justify-content: space-between;

					form {
						display: flex;
						align-items: center;
						margin-right: 15px;

						input {
							padding: 6px;
							font-size: 14px;
							border: 1px solid #ccc;
							border-radius: 5px;
							width: 220px;
							background: transparent;
							transition: all 200ms ease;
							color: #fff;
						}

						::placeholder {
							color: #ccc;
							font-size: 14px;
						}

						.search {
							border: 1px solid #fff;
							border-radius: 5px;
							padding: 4px 12px;
							outline: none;
							background: none;
							margin-left: 3px;
							cursor: pointer;

							&:hover {
								background: @color1;
								border: none;
								border: 1px solid transparent;
							}

							img {
								width: 17px;
							}
						}
					}

					.social-media {
						border: 1px solid #fff;
						border-radius: 5px;
						padding: 4px 13px;
						margin-right: 3px;

						&:hover {
							background: @color1;
							border: 1px solid transparent;
						}

						img {
							width: 15px;
						}
					}
				}
			}

			.flexbox-nav-wrapper {
				.nav-button-close {
					position: absolute;
					display: none;
					width: 100%;
					top: 20px;
					left: 0;

					.container {
						display: flex;
						justify-content: flex-end;

						&:before,
						&:after {
							content: none;
						}
					}

					button {
						outline: none;
						border: none;
						background: none;
						padding: 15px;
						display: flex;
						justify-content: center;
						align-items: center;

						.button-bar {
							display: block;
							position: absolute;
							width: 30px;
							height: 2px;
							background: #222;

							&:nth-child(1) {
								transform: rotate(45deg);
							}

							&:nth-child(2) {
								transform: rotate(-45deg);
							}
						}
					}
				}

				.header-bottom {
					.flexbox-nav {
						display: flex;
						justify-content: space-between;
						border-top: 1px solid #fff;
						padding-top: 10px;

						li {
							padding: 0 10px;

							a {
								position: relative;
								color: #fff;
								font-size: 14px;
								transition: all 250ms ease;

								&:hover {
									color: @color1;

									&::before {
										content: '';
										position: absolute;
										top: -15px;
										width: 100%;
										height: 3px;
										background: @color1;
									}
								}
							}
						}
					}
				}
			}
		}

		.nav-button {
			padding: 20px;
			border: none;
			background: none;
			flex-direction: column;
			align-items: flex-end;
			justify-content: center;
			outline: none;
			display: none;

			.button-bar {
				height: 2px;
				width: 24px;
				background: @color1;
				border-radius: 10px;
				transition: all 0.4s;

				&:nth-child(2) {
					margin-top: 4px;
					width: 27px;
				}

				&:nth-child(3) {
					margin-top: 4px;
					width: 30px;
				}
			}

			&:hover,
			&:focus {
				.button-bar {
					width: 30px !important;
				}
			}
		}
	}
}

section.section-welcome {
	margin-top: -140px;
	height: 550px;

	.welcome-wrapper {
		height: 100%;
		display: flex;
		flex-direction: column;

		.owl-carousel-welcome {
			height: 100%;
			display: flex;
			align-items: center;
			justify-content: center;

			.owl-stage,
			.owl-stage-outer,
			.owl-item,
			.item {
				height: 100%;
			}

			.item {
				position: relative;

				img {
					width: 100%;
					height: 100%;
					object-fit: cover;
				}
			}

			.heading-carousel {
				position: absolute;
				padding: 20px 0;
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				border: 4px solid #fff;
				width: 60%;
				bottom: 30%;
				left: 20%;

				h1,
				h2 {
					color: #fff;
					font-size: 36px;
					text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.5);
					text-align: center;
				}
			}
		}
	}
}

.searching-content {
	background-color: @color1;
	height: 100px;
	display: flex;
	align-items: center;

	.searching-wrapper {
		display: flex;
		justify-content: flex-start;

		.search {
			border: 1px solid #fff;
			border-radius: 5px;
			padding: 3px 7px;
			outline: none;
			background: none;
			cursor: pointer;

			img {
				width: 20px;
			}
		}

		input,
		select {
			padding: 0 8px;
			font-size: 14px;
			border: 1px solid #fff;
			border-radius: 5px;
			color: #333;
			width: 20%;
			background: #fff;
			margin-right: 5px;
		}

		select:nth-of-type(2) {
			width: 38%;
		}

		::placeholder,
		select {
			color: #888888;
		}
	}
}

section.section-news {
	padding: 150px 0 60px 0;

	.news-wrapper {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		padding-top: 50px;

		.news-card {
			width: 23%;
			height: 450px;
			padding-bottom: 15px;
			box-shadow: 6px 0px 17px -5px rgba(0, 0, 0, 0.75);
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			align-items: center;

			&:hover {
				.card-description p {
					color: @color1;
				}
			}

			.card-image {
				position: relative;
				height: 50%;

				img {
					width: 100%;
					height: 95%;
				}

				.date {
					position: absolute;
					bottom: 30px;
					left: 10px;
					background-color: #5cb85c;
					width: 80px;
					border-radius: 3px;
					display: flex;
					justify-content: center;

					p {
						font-size: 10px;
						color: #fff;
					}
				}
			}

			.card-description {
				padding: 20px 15px;
				height: 40%;
				display: flex;
				flex-direction: column;
				justify-content: flex-start;

				p {
					color: #222;
					font-size: 14px;
					text-transform: uppercase;
				}
			}
		}
	}
}

.aktualnosci-section-content {
	width: 75%;
	padding-left: 30px;

	.news-wrapper {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;

		.news-card {
			width: 32%;
			height: 450px;
			padding-bottom: 15px;
			box-shadow: 6px 0px 17px -5px rgba(0, 0, 0, 0.75);
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			align-items: center;
			margin-top: 40px;

			&:first-of-type,
			&:nth-of-type(2),
			&:nth-of-type(3) {
				margin-top: 0;
			}

			.card-image {
				position: relative;
				height: 50%;

				img {
					width: 100%;
					height: 90%;
				}

				.date {
					position: absolute;
					bottom: 30px;
					left: 10px;
					background-color: #5cb85c;
					width: 80px;
					border-radius: 3px;
					display: flex;
					justify-content: center;

					p {
						font-size: 10px;
						color: #fff;
					}
				}
			}

			.card-description {
				padding: 20px 15px;
				height: 40%;
				display: flex;
				flex-direction: column;
				justify-content: flex-start;

				p {
					color: #222;
					font-size: 14px;
					text-transform: uppercase;
				}
			}
		}

		.box-hidden {
			width: 33%;
			background: transparent;
			visibility: hidden;
		}
	}

	.pagination-list {
		display: flex;
		justify-content: center;
		padding-top: 50px;

		li {
			padding: 5px 10px;
			font-size: 18px;
			transition: all 250ms ease;

			&:hover {
				background-color: #ddd;

				a {
					color: #fff;
				}
			}

			a {
				color: #555;
				padding: 5px;
			}

			&.active {
				color: #fff;
				background-color: @color1;
				border-radius: 3px;

				a {
					color: #fff;
				}
			}
		}
	}
}

section.section-register {
	background: url(../img/pictures/fotolia_79200372.jpg);
	background-size: cover;
	background-position: center;
	background-attachment: fixed;
	padding: 30px 0;

	.register-wrapper {
		display: flex;
		height: 400px;
		justify-content: space-between;
		align-items: center;

		.register-content {
			height: 300px;
			width: 45%;
			padding: 30px;
			background-color: rgba(#fff, 0.8);
			border-radius: 5px;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: space-around;

			.register-description {
				text-align: center;

				h3 {
					font-size: 18px;
					text-transform: uppercase;
				}

				p {
					padding-top: 10px;
					font-size: 15px;
				}

				.newsletter-input {
					padding-top: 10px;

					input {
						padding: 8px;
						font-size: 14px;
						border: 1px solid #b0b0b0;
						border-radius: 5px;
						background: transparent;
						transition: all 200ms ease-in;
					}

					::placeholder {
						color: #555;
						font-size: 14px;
					}
				}
			}
		}
	}
}

section.section-about {
	padding: 80px 0;

	.about-wrapper {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;

		.about-column {
			width: 33%;
			padding-top: 30px;

			img {
				width: 100%;
			}

			&:nth-of-type(2) {
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: space-between;
			}

			p {
				font-size: 15px;

				&:nth-of-type(2) {
					margin-top: 20px;
				}
			}
		}
	}
}

section.static-page-section-content {
	.content-wrapper {
		display: flex;
		padding: 60px 0;
		flex-wrap: wrap;

		.navigation-sidebar {
			width: 25%;

			.nav {
				padding-bottom: 50px;

				input {
					padding: 8px;
					font-size: 14px;
					border: 1px solid #b0b0b0;
					border-radius: 5px;
					background: transparent;
					transition: all 200ms ease;
					margin-bottom: 20px;
					width: 100%;
				}

				::placeholder {
					color: #555;
					font-size: 14px;
				}

				ul {
					li {
						&.heading-nav {
							border-radius: 3px;
							padding: 15px;
							background-color: #006d3a;
							border-color: #006d3a;

							h3 {
								text-transform: uppercase;
								text-align: left;
								color: #fff;
								font-size: 14px;
							}
						}

						a {
							position: relative;
							display: block;
							padding: 10px 30px;
							margin-bottom: -1px;
							background-color: #fff;
							border: 1px solid #ddd;
							border-radius: 3px;
							color: #555;
							font-size: 14px;
							transition: 250ms background-color ease;

							&:before {
								content: '';
								position: absolute;
								left: 15px;
								top: 18px;
								background: url(../img/pictures/double-arrow-dark.svg);
								display: block;
								width: 8px;
								height: 8px;
							}

							&:hover {
								background-color: #f5f5f5;
							}
						}

						&:last-of-type {
							:after {
								content: '';
								position: absolute;
								bottom: 0;
								left: 0;
								background: @color1;
								display: block;
								width: 100%;
								height: 2px;
								border-radius: 3px;
							}
						}
					}
				}
			}

			.catalog {
				padding: 8px;
				margin-bottom: 4px;
				background-color: #f5f5f5;
				border: 1px solid #e3e3e3;
				border-radius: 0px;
				display: flex;
				flex-direction: column;

				h3 {
					font-size: 14px;
					color: #333;
					font-weight: 700;
				}

				input,
				select {
					padding: 8px;
					font-size: 14px;
					border: 1px solid #ccc;
					border-radius: 5px;
					background: #fff;
					margin-top: 12px;
				}

				::placeholder,
				select {
					color: #555;
				}

				.btn-default {
					align-self: flex-start;
					margin-top: 12px;
				}
			}
		}

		.navigation-description {
			width: 75%;
			padding-left: 30px;

			.description-content {
				padding-bottom: 50px;

				.heading-subpage {
					p {
						font-size: 20px;
					}
				}

				p {
					margin-top: 20px;
					font-size: 14px;
				}

				a {
					color: @color1;
				}

				ul {
					li {
						padding: 5px 0;
						font-size: 14px;
						margin-left: 18px;
					}
				}

				.wrapper {
					padding-bottom: 40px;
					display: flex;
					flex-direction: column;

					h4 {
						font-size: 16px;
						line-height: 26px;
						font-weight: 700;
					}

					h3 {
						font-size: 16px;
						line-height: 26px;
						text-align: center;
						font-weight: 700;
						text-transform: uppercase;
					}

					p {
						text-align: center;
					}

					ul {
						padding-top: 20px;
						text-align: center;

						h4,
						p {
							font-size: 16px;
							padding-bottom: 10px;
						}

						li {
							font-size: 14px;
							padding: 4px 0;
						}
					}

					.table {
						display: flex;
						flex-direction: column;
						text-transform: uppercase;
						padding-top: 20px;
						margin-bottom: 0;

						.table-heading {
							display: flex;
							justify-content: space-between;

							h5 {
								font-size: 15px;
								font-weight: 700;
								padding: 15px 0;
							}
						}

						.column {
							display: flex;
							flex-direction: column;
							justify-content: space-between;

							ul {
								border-top: 1px solid #ccc;
								display: flex;
								justify-content: space-between;
								padding: 0;

								li {
									padding: 15px 0;
									margin: 0;
								}
							}
						}

						.note {
							padding-top: 30px;

							p {
								text-align: left;
								text-transform: none;
							}
						}
					}
				}

				table {
					margin: 50px 0;

					.btn-default-light {
						color: #fff;
						font-size: 14px;
					}

					tr {
						background-color: #fefefe;
						border-top: 1px solid #ddd;

						&:nth-of-type(odd) {
							background-color: #f9f9f9;
						}

						td {
							font-size: 13px;
							color: #333;
							padding: 10px;

							img {
								width: 50px;
							}

							&:nth-of-type(2),
							&:nth-of-type(3) {
								padding-top: 25px;
							}

							&:last-of-type {
								padding: 15px 10px 10px 10px;
								text-align: right;
							}
						}
					}
				}

				.management {
					display: flex;
					justify-content: space-between;
					align-items: center;
					flex-wrap: wrap;

					&:not(:last-of-type) {
						padding-bottom: 40px;
					}

					.person {
						width: 33%;
						display: flex;
						align-items: flex-start;
						padding: 15px 0;

						// img {
						// 	width: 55px;
						// }

						.personal-data {
							padding-left: 10px;

							p {
								margin: 0;

								&.name {
									font-size: 20px;
									border-bottom: 1.5px solid @color1;
								}

								&.position {
									margin-top: 10px;
									font-size: 14px;
								}
							}
						}
					}

					.box-hidden {
						width: 33%;
						background: transparent;
						visibility: hidden;
					}
				}

				.content-gallery {
					display: flex;
					flex-wrap: wrap;
					justify-content: space-between;

					.item {
						width: 32.5%;
						margin: 5px 0;

						img {
							height: 100%;
							width: 100%;
						}
					}

					.box-hidden {
						width: 33%;
						background: transparent;
						visibility: hidden;
					}
				}
			}

			.avatar {
				display: flex;
				justify-content: center;
				padding-bottom: 50px;
			}
		}
	}
}

section.policy-section-content {
	padding-bottom: 60px;

	.content-wrapper {
		display: flex;
		flex-direction: column;
		padding-top: 60px;

		.content-description {
			h3 {
				font-size: 16px;
				line-height: 26px;
				margin-bottom: 10px;
			}

			p {
				font-size: 15px;
				color: #333;

				&:not(first-of-type) {
					margin-top: 10px;
				}
			}

			ul {
				list-style-type: decimal;
				padding-left: 30px;

				li {
					padding: 3px 0;
					font-size: 14px;

					ul {
						padding-left: 30px;
					}

					a {
						color: @color1;
					}
				}
			}
		}
	}
}

section.formularz-zgloszenia-section-content {
	padding-bottom: 60px;

	.content-wrapper {
		display: flex;
		flex-direction: column;

		.form-wrapper {
			padding-top: 40px;

			form {
				.form-row {
					display: flex;
					justify-content: space-between;
					width: 100%;
					margin: 0;

					.w-15 {
						width: 15%;
					}

					.w-72 {
						width: 72%;
					}

					.w-48 {
						width: 48.5%;
					}
				}

				.input {
					display: flex;
					flex-direction: column;
					align-items: flex-start;
					justify-content: flex-end;
					width: 100%;

					input,
					select {
						padding: 6px;
						font-size: 14px;
						border: 1px solid #ddd;
						border-radius: 5px;
						margin-bottom: 15px;
						width: 100%;
						transition: all 350ms ease-in-out;

						&:focus {
							outline-color: @color1;
							border: 1px solid transparent;
							border-radius: 5px;
						}
					}

					input {
						background: #fcf8e3;
					}

					.form-control[disabled] {
						background-color: #eee;
					}

					label {
						font-size: 14px;
						color: #333;
					}

					::placeholder,
					select {
						color: #888888;
					}
				}
			}

			.alert {
				margin-top: 10px;

				p {
					font-size: 14px;
				}
			}
		}

		.card-body {
			border: none;
		}

		.agreement {
			padding-top: 15px;

			form {
				display: flex;
				flex-direction: column;
				align-items: flex-start;

				.input-content {
					display: flex;
					align-items: flex-start;
					padding: 5px 0;

					p {
						font-size: 14px;
						margin-top: -4px;
						margin-left: 8px;
					}
				}
			}
		}

		.btn {
			align-self: center;
			margin-top: 20px;
		}
	}
}

section.katalog-section-content {
	padding-bottom: 60px;

	.content-wrapper {
		padding-top: 60px;

		.table-wrapper {
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding-top: 30px;

			.table-img {
				width: 28%;

				img {
					width: 100%;
				}
			}

			.table {
				width: 70%;
				font-size: 14px;
			}
		}
	}
}

section.kontakt-section-content {
	padding-bottom: 60px;

	.content-wrapper {
		.form-wrapper {
			padding-top: 40px;

			form {
				.form-row {
					display: flex;
					justify-content: space-between;
					width: 100%;
					margin: 0;

					.w-48 {
						width: 48.5%;
					}
				}

				.input {
					display: flex;
					flex-direction: column;
					align-items: flex-start;
					width: 100%;

					input,
					textarea {
						padding: 6px;
						font-size: 14px;
						border: 1px solid #ddd;
						border-radius: 5px;
						margin-bottom: 15px;
						width: 100%;
						transition: all 200ms ease-in-out;
					}

					label {
						font-size: 14px;
						color: #333;
					}

					::placeholder,
					select {
						color: #888888;
					}
				}
			}
		}

		.btn {
			margin-top: 20px;
			padding: 8px 10px;

			svg {
				width: 18px;
				margin-right: 5px;
				height: 100%;
				fill: #fff;
			}

			&:hover {
				svg {
					fill: @color1;
				}
			}
		}

		.contact-address {
			padding-top: 40px;

			.contact-content {
				display: flex;
				align-items: center;
				padding-top: 20px;

				.column {
					width: 50%;

					h3 {
						font-size: 24px;
						margin-bottom: 10px;
					}

					p {
						margin-top: 10px;
						font-size: 18px;
					}
				}
			}
		}

		.contact-person {
			display: flex;
			justify-content: space-between;
			flex-wrap: wrap;

			&:not(:last-of-type) {
				padding-bottom: 40px;
			}

			.person {
				width: 47%;
				display: flex;
				align-items: flex-start;
				padding: 15px 0;

				.personal-data {
					padding-left: 10px;

					p {
						margin-top: 5px;

						&.name {
							font-size: 24px;
							border-bottom: 1.5px solid @color1;
							width: 300px;
						}

						&.position {
							font-size: 18px;
						}

						&.task {
							a {
								color: @color1;
							}
						}
					}
				}
			}
		}
	}
}

section.section-footer {
	padding: 30px 0;
	background-color: #4b4b4b;
	position: relative;

	.footer-flexbox {
		position: relative;
		padding-top: 50px;
		display: flex;
		justify-content: space-between;

		&:before {
			content: '';
			display: block;
			position: absolute;
			width: 100%;
			top: -30px;
			height: 4px;
			background-color: @color1;
		}

		.flexbox-service {
			width: 33%;

			&:not(:first-of-type) {
				padding-left: 40px;
			}

			.logo {
				height: 100px;
			}

			.social-media-icon {
				margin-top: 10px;
				width: 40px;
				transition: 200ms transform ease;

				&:hover {
					transform: scale(1.05);
				}
			}

			p {
				margin-top: 10px;
				font-size: 13px;
				font-weight: 400;
				line-height: 20px;
				color: #fff;
			}

			h3 {
				margin-left: -15px;
			}

			.footer-heading {
				margin-top: 0;
				padding-bottom: 10px;
				font-size: 18px;
				color: #fcfcfc;
			}

			ul {
				li {
					position: relative;
					margin-top: 10px;
					color: #fff;
					font-size: 14px;

					&:before {
						content: '';
						position: absolute;
						left: -15px;
						top: 6px;
						background: url(../img/pictures/double-arrow.svg);
						display: block;
						width: 10px;
						height: 10px;
					}

					a {
						color: #fff;
						font-size: 13px;
						text-transform: uppercase;

						&:hover {
							color: #000;
						}
					}
				}
			}

			&.contact {
				h3 {
					margin-left: 10px;
				}

				p {
					margin: 0 0 5px 0;
				}

				.md-font-size {
					font-size: 18px;
					line-height: 24px;
				}

				ul {
					.contact-wrapper {
						display: flex;
						align-items: flex-start;

						img {
							width: 32px;
							margin: 8px 10px 0 0;
						}
					}

					li {
						margin-top: 0;
						padding-bottom: 8px;

						&:before {
							display: none;
						}

						a {
							font-size: 18px;
							text-transform: lowercase;

							&:hover {
								color: @color1;
							}
						}
					}
				}
			}
		}
	}

	.footer-credits {
		border-top: 1px solid rgba(255, 255, 255, 0.2);
		margin-top: 50px;
		padding-top: 50px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		position: relative;

		p {
			font-size: 13px;
			color: #fff;
			font-weight: 300;

			a {
				color: #fff;
			}
		}
	}

	.alert {
		position: relative;
		margin: 50px 0 0 0;
		padding: 50px 0 0 0;
		border-radius: 0;
		background: none;
		border: none;
		border-top: 1px solid rgba(255, 255, 255, 0.2);

		p {
			font-size: 13px;
			color: #fff;
			font-weight: 300;

			a {
				color: #fcfcfc;
				cursor: pointer;
				transition: all 0.3s;

				&:hover {
					color: @color1;
				}
			}
		}
	}
}